@import url("https://fonts.googleapis.com/css2?family=Marcellus&display=swap");

:root {
  --primary: #395e10;
  --primary-rgb: 57, 94, 16;

  --secondary: #c8c58f;
  --secondary-rgb: 200, 197, 143;

  --dark: #121211;
  --dark-rgb: 18, 18, 17;

  --light: #ffffff;
  --light-rgb: 255, 255, 255;

  --transition: 0.25s ease;
}

* {
  cursor: default;
  font-family: "Marcellus", serif;
  color: var(--light);
}

html {
  overflow: hidden;
}

body {
  background-color: var(--dark);
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.title {
  font-weight: 600;
  font-size: 32px;
  color: var(--secondary);
  letter-spacing: 3px;
  text-transform: uppercase;
}

.primary-btn {
  font-size: 20px;
  letter-spacing: 1px;
  background-color: rgba(var(--primary-rgb), 0.5);
  color: var(--dark);
  border: 1px solid rgba(var(--secondary-rgb), 0.5);
  border-radius: 5px;
  font-weight: 600;
  outline: none;
  min-width: 200px;
  padding: 20px;
  transition: var(--transition);
}

.primary-btn:disabled {
  cursor: not-allowed;
}

.primary-btn:hover {
  background-color: rgba(var(--primary-rgb), 0.9);
  color: var(--secondary);
  border: 1px solid rgba(var(--secondary-rgb), 0.5);
  transform: scale(1.025);
  letter-spacing: 2px;
}

.buy-qty-text {
  color: var(--secondary);
  font-size: 28px;
  margin: 0px 35px 0px 30px;
}

.plus-minus-btn {
  outline: none;
  text-align: center;
  width: 40px;
  height: 40px;
  border: 1px solid rgba(var(--secondary-rgb), 0.25);
  background: rgba(var(--secondary-rgb), 0.25);
  padding-bottom: 0px;
  border-radius: 5px;
  transition: var(--transition);
}

.plus-minus-btn div {
  cursor: pointer;
  font-size: 18px;
  transform: scale(2);
  color: var(--secondary);
  transition: var(--transition);
}

.plus-minus-btn:hover {
  background-color: rgba(var(--secondary-rgb), 0.75);
}

.plus-minus-btn:hover div {
  color: var(--dark) !important;
}

.qty {
  font-size: 60px;
  margin: 30px 0px;
}

.cost {
  margin: 5px auto;
  max-width: 400px;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
}

.social-btns a {
  padding-top: 5px;
  border-radius: 50px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: var(--transition);
}

.social-btns i {
  color: var(--light);
  font-size: 22px;
  cursor: pointer;
  transition: inherit;
}

.social-btns a:hover i {
  transform: scale(1.5);
  color: var(--primary);
}

.mint-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 60px;
  line-height: 20px;
  border-radius: 20px;
  background: rgba(var(--light-rgb), 0.075);
  overflow: hidden;
  box-shadow: 0 0 30px 5px rgba(var(--dark-rgb), 0.2),
    0 0 60px 5px rgba(var(--primary-rgb), 0.2),
    0 0 300px 5px rgba(var(--secondary-rgb), 0.2);
}

.mint-container:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(var(--dark-rgb), 0.75);
  border-radius: 20px;
  backdrop-filter: blur(30px);
}

@media screen and (max-width: 390px) {
  .mint-container {
    padding: 25px !important;
  }
}

.mint-container .content {
  align-self: center;
}

::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 992px) {
  .title {
    font-size: 20px;
  }

  .qty {
    font-size: 40px;
    margin: 10px 0px;
  }

  .primary-btn {
    min-width: 280px;
    font-size: 16px;
  }
}
